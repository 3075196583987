import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
interface Props {
    background: string
    image: string
    quote: string
    name: string
}

const Quote = ({background, image, name, quote}: Props) => {
    return (
        <div className="quote" style={{background: `url(${background})`}}>
                    <div className="quote-grey">
                        <Container className="quote-container">
                            <Row className="center">
                                <Col sm={4} className="">
                                    <div className="quote-container-padding">
                                    <div className="quote-container__image" style={{background: `url(${image})`}}></div>
                                    </div>
                                </Col>
                                <Col sm={8}>
                                    <div className="quote-container-content">
                                        <p className="quote-container-content__mark">“</p>
                                        <p className="quote-container-content__text">{quote}</p>
                                        <p className="quote-container-content__name">- {name}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
    )
}

export default Quote