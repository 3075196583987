import Page from "../Components/Page"
import Quote from "../Components/Quote"
import TextImage from "../Components/TextImage"
import image from "../Images/work/smartmockups_lkccnj3q.jpg"
import webDev from "../Images/work/smartmockups_lkccs6lt.jpg"
import Button from "react-bootstrap/Button"
import d2d from "../Images/d2d.png"
import DevProcess from "../Components/DevProcess"
import CasePreview from "../Components/CasePreview"
import { BsShopWindow, BsWindowStack, BsWindowFullscreen } from "react-icons/bs"
import Packages from "../Components/Packages"
import { Link, useNavigate } from "react-router-dom";

const Websites = () => {
    const navigate = useNavigate()

    return (
        <Page page="Websites">
            <TextImage black title="DESIGN & DEVELOPMENT" image={image}>
                <p className="mb-2">Toseland Digital is dedicated to providing top-quality website design and development 
                            services that help businesses establish a strong online presence and achieve their business goals.</p>
                <p className="mb-2">We take a collaborative and personalized approach to website development, working closely with our clients to understand their unique needs and deliver customized solutions that exceed their expectations. With years of experience in the industry and a team of skilled designers and developers, we are committed to 
                                staying ahead of the curve and delivering cutting-edge website development solutions that are tailored to the 
                                needs of our clients.</p>
                                <p className="mb-4"> Contact us today to learn more about how we can help your business succeed.</p>

                <Link to={"/contact-us/website"} className='tdButtonGradient'>RECEIVE QUOTE</Link>

            </TextImage>
            <div className="packages-bg">

            <DevProcess/>
</div>
<Quote name="DAY2DAY" quote="Toseland Digital designed and built an amazing online tool for our customers to manage there finances. Would highly recommend to all businesses." background={webDev} image={d2d} />

            <div className="packages-bgthree">

            <Packages
                title="WEBSITE PACKAGES"
                description="Our website packages have been carefully crafted to cater to the diverse needs of our clients, providing comprehensive solutions that guarantee a strong online presence and a remarkable user experience. "
                items={[
                    {
                        icon: <BsWindowFullscreen color="white" size={60} />,
                        title: "STARTER WEBSITE",
                        price: 500,
                        items: [
                            "Project consultation",
                            "3-5 Website pages",
                            "Approved designs",
                            "Hosting & security",
                            "SEO optimization"
                        ]
                    },
                    {
                        icon: <BsWindowStack color="white" size={60} />,
                        title: "PROFESSIONAL WEBSITE",
                        price: 900,
                        items: [
                            "Project consultation",
                            "3-10 Website pages",
                            "Approved designs",
                            "Hosting & security",
                            "SEO optimization",
                            "Marketing options",
                            "Database & storage",
                            "CMS user control"
                        ]
                    },
                    {
                        icon: <BsShopWindow color="white" size={60} />,
                        title: "ECOMMERCE WEBSITE",
                        price: 1300,
                        items: [
                            "Project consultation",
                            "3-20 Website pages",
                            "Approved designs",
                            "Hosting & security",
                            "SEO optimization",
                            "Marketing options",
                            "Database & storage",
                            "CMS user control",
                            "Ecommerce ready"
                        ]
                    }
                ]}
            />
            </div>
            <CasePreview />
        </Page>
    )
}

export default Websites