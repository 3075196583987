import Page from "../Components/Page"
import TextImage from "../Components/TextImage"
import image from "../Images/textImage.png"
import Button from "react-bootstrap/Button"
import FsLightbox from "fslightbox-react";
import { useState } from "react"
import d2d from "../Images/work/d2dWebColor.png"
import d2dHome from "../Images/work/d2dWebHome.png"
import thercoSerck from "../Images/work/thercoWeb.png"
import thercoSerckEnq from "../Images/work/thercoEnq.png"
import thercoSerckInv from "../Images/work/thercoIn.png"
import thercoSerckPar from "../Images/work/thercoPar.png"
import flexFull from "../Images/work/Flex.png"
import vault from "../Images/work/vault.png"
import d2dLogo from "../Images/d2d.png"
import thercoLogo from "../Images/therco.png"
import vsLogo from "../Images/vsLogo.png"
import fitbLogo from "../Images/fitbLogo.png"
import flexLogo from "../Images/flexLogo.png"
import fitb from "../Images/work/FITBWeb.jpg"
import fitbMon from "../Images/work/FITBMon.png"
import fitbDrink from "../Images/work/FITBDrink.png"
const CaseStudies = () => {
    const [open, setOpen] = useState(false);

    const [images, setImages] = useState<string[]>([])
    return (
        <Page page="Recent Case Studies">
            <FsLightbox
                slide={1}
                toggler={open}
                sources={images}
            />
            <div className="textImage-bg">
            <TextImage title="FLEX SPORTSWEAR" image={flexLogo}>
                <p className="mb-2">Flex Sportswear is a upcoming sportswear brand specialising in footwear, especially grip sport socks. They provide athletes and fitness enthusiasts with top-quality products designed to enhance performance and safety.</p>
                <p className="mb-4">Working on Flex's website was a memorable experience that tested our skills and pushed us to deliver our best. The combination of creativity, problem-solving, and collaboration with the client made this project a fulfilling endeavor, contributing to the success of the sportswear brand in the digital realm.</p>
                <Button onClick={() => {
                            setImages([flexFull])
                            setOpen(!open)
                        }} className='tdButtonGradient'>VIEW PROJECT</Button>
            </TextImage>
            </div>
            <TextImage reverse title="VAULT STUDIOS" image={vsLogo}>
                <p className="mb-2">Vault Studios is a new music studio in Sheffield, South Yorkshire offering a place for progressing artist to record, master and deliver there albums. This studio offers state of the art equipment to get the best sound from their clients.</p>
                <p className="mb-4">We really enjoyed working on this project. The client supplied us with a logo and a colour scheme and offered us full creative freedom to design and create them their perfect website.</p>
                <Button onClick={() => {
                            setImages([vault])
                            setOpen(!open)
                        }} className='tdButtonGradient'>VIEW PROJECT</Button>
            </TextImage>
            <div className="textImage-bg">
            <TextImage title="DAY2DAY" image={d2dLogo}>
                <p className="mb-2">DAY2DAY Finance is a free online personal finance management tool designed to help people manage their day to day finances including outgoings, savings and reciept management.</p>
                <p className="mb-4">We really enjoyed working with DAY2DAY on this project and the creative freedom they gave us to create a easy to use, responsive web application. It was a project that really appealed to us because it was a free to use application that could provide help to people of all ages.</p>
                <Button onClick={() => {
                            setImages([d2d, d2dHome])
                            setOpen(!open)
                        }} className='tdButtonGradient'>VIEW PROJECT</Button>
            </TextImage>
            </div>
            <TextImage reverse title="THERCO-SERCK" image={thercoLogo}>
                <p className="mb-2">Therco - Serck designs and manufactures an array of heat transfer equipment in Sheffield, at the heart of the UK's high-tech manufacturing industry.</p>
                <p className="mb-4">The company combines holistic design activity with cutting edge manufacturing techniques to deliver world class heat exchangers to the marine, powergeneration, petrochemical, oil & gas markets.</p>
                <Button onClick={() => {
                            setImages([thercoSerck, thercoSerckEnq, thercoSerckInv, thercoSerckPar])
                            setOpen(!open)
                        }} className='tdButtonGradient'>VIEW PROJECT</Button>
            </TextImage>
            <div className="textImage-bg">
            <TextImage title="FOX IN THE BOX" image={fitbLogo}>
                <p className="mb-2">Fox In The Box are a vibrant and lively establishment located in Dronfield that prides itself on providing exceptional service, delicious cocktails, and a fun atmosphere for all. Their bar is the perfect place to unwind after a long day at work, catch up with friends, or celebrate a special occasion.</p>
                <p className="mb-4">The Fox In The Box project was a great project to work on. It combined the creative skills of making the visuals of the site and the technical skills of creating a custom CMS on the back-end.</p>
                <Button onClick={() => {
                            setImages([fitb, fitbDrink, fitbMon])
                            setOpen(!open)
                        }} className='tdButtonGradient'>VIEW PROJECT</Button>
            </TextImage>
            </div>
        </Page>
    )
}

export default CaseStudies