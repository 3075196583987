import React from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import PackagesItem from "./PackagesItem";

export interface PackagesItemType {
    icon: React.ReactNode
    title: string
    price: number
    items: string[]
}

interface Props {
    title: string;
    description: string;
    items: PackagesItemType[];
}

const Packages = ({ title, description, items }: Props) => {
    return (
        
        <Container className="packages">
            <div className="packages-container">
                <h2 className="packages-container__title">{title}</h2>
                <p className="packages-container__desc">{description}</p>
            </div>
            <Row className="packages-items">
                {items.map((item) => <PackagesItem item={item} />)}
            </Row>
            <p className="packages__extra">*Prices shown here are estimates based on previous projects. Please enquire to receive a full quote tailored to you.</p>
        </Container>

    )
}

export default Packages