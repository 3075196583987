import { Container, Row } from "react-bootstrap"
import Page from "../Components/Page"

const Terms = () => {
    return (
        <Page page="Terms & Conditions">
            <Container className="pt-4 pb-4">
                <Row className="mb-3">
                <h3>1. Services Offered:</h3>
                <p>Toseland Digital offers web design, web development, digital marketing, branding, and related services to clients worldwide.</p>
                </Row>
                <Row className="mb-3">
                <h3>2. Client Obligations:</h3>
                <p>Clients must provide accurate and complete information required for project execution. You are responsible for all content, images, and other materials provided to us, ensuring they do not infringe any copyrights or violate any laws. We reserve the right to reject any content that we find inappropriate or objectionable.</p>
                </Row>
                <Row className="mb-3">
                <h3>3. Payments:</h3>
                <p>Clients agree to pay Toseland Digital for the services rendered as per the agreed terms and pricing. Payments shall be made in full, according to the payment schedule outlined in the project proposal. Delayed payments may incur additional charges and may result in a delay in project delivery.</p>
                </Row>
                <Row className="mb-3">
                <h3>4. Project Timeline:</h3>
                <p>We will provide an estimated project timeline based on the scope of work and complexity. While we strive to meet deadlines, factors beyond our control may lead to delays. We will keep you informed of any significant changes to the timeline.</p>
                </Row>
                <Row className="mb-3">
                <h3>5. Intellectual Property:</h3>
                <p>Upon receipt of full payment, the client will own the intellectual property rights to the final deliverables, except for any third-party elements used, such as stock images or licensed software. Toseland Digital retains the right to showcase the completed work in portfolios or promotional materials.</p>
                </Row>
                <Row className="mb-3">
                <h3>6. Revisions and Changes:</h3>
                <p>We aim to deliver high-quality work, but minor revisions are included in the project scope. Any additional changes or revisions may incur extra charges, and we will communicate these costs before proceeding.</p>
                </Row>
                <Row className="mb-3">
                <h3>7. Confidentiality:</h3>
                <p>We respect your privacy and treat all information shared with us as confidential. We will not disclose any sensitive or proprietary information to third parties without your explicit consent, except as required by law.</p>
                </Row>
                <Row className="mb-3">
                <h3>8. Termination of Services:</h3>
                <p>Either party may terminate the project at any stage for a material breach of the terms. Upon termination, clients are responsible for all fees incurred up to that point. Any prepaid amounts for unfinished work will be refunded proportionally.</p>
                </Row>
                <Row>
                <h3>9. Limitation of Liability:</h3>
                <p>Toseland Digital will not be liable for any direct, indirect, incidental, consequential, or special damages arising from the use or inability to use our services.</p>
                </Row>
            </Container>
        </Page>
    )
}

export default Terms