import Page from "../Components/Page"
import Quote from "../Components/Quote"
import TextImage from "../Components/TextImage"
import image from "../Images/work/smartmockups_lkccwoi1.jpg"
import webDev from "../Images/work/smartmockups_lkcczp5q.jpg"
import Button from "react-bootstrap/Button"
import therco from "../Images/therco.png"
import DevProcess from "../Components/DevProcess"
import CasePreview from "../Components/CasePreview"
import { BsFillPersonFill, BsPhone, BsFileRichtextFill } from "react-icons/bs"
import Packages from "../Components/Packages"
import { Link, useNavigate } from "react-router-dom";

const MobileApps = () => {
    const navigate = useNavigate()

    return (
        <Page page="Mobile Apps">
            <TextImage black title="DESIGN & DEVELOPMENT" image={image}>
                <p className="mb-2">Toseland Digital specializes in developing innovative and user-friendly mobile applications that help businesses of all sizes reach their target audience and achieve their business goals.</p>
                <p className="mb-2">We are dedicated to providing top-quality app development services that are tailored to the unique needs of our clients. Our team of skilled designers and developers work closely with our clients to understand their vision and develop customized solutions that exceed their expectations.</p>
                <p className="mb-4"> Contact us today to learn more about how we can help your business succeed.</p>
                <Link to={"/contact-us/mobile-application"} className='tdButtonGradient'>RECEIVE QUOTE</Link>

            </TextImage>
            <div className="packages-bg">
            <DevProcess />
            </div>
            <Quote name="Therco-Serck" quote="Our ERP system is exactly what we were looking for. It spans all devices across our company making our information easy  to access." background={webDev} image={therco} />
            <div className="packages-bgthree">
            <Packages
                title="MOBILE APP PACKAGES"
                description="Our mobile app packages are tailored to meet the diverse needs of businesses and individuals seeking to thrive in the rapidly evolving world of mobile technology. These packages have garnered praise for their flexibility, user-centric approach, cutting-edge technology, and commitment to delivering high-quality, reliable apps."
                items={[
                    {
                        icon: <BsPhone color="white" size={60} />,
                        title: "STARTER APPLICATION",
                        price: 800,
                        items: [
                            "Project consultation",
                            "3-5 Application pages",
                            "Approved designs",
                            "Multi-device testing",
                            "App store deployment"
                        ]
                    },
                    {
                        icon: <BsFillPersonFill color="white" size={60} />,
                        title: "USER BASED APPLICATION",
                        price: 1400,
                        items: [
                            "Project consultation",
                            "3-10 Application pages",
                            "Approved designs",
                            "Multi-device testing",
                            "App store deployment",
                            "Marketing options",
                            "Database & storage",
                            "CMS web portal"
                        ]
                    },
                    {
                        icon: <BsFileRichtextFill color="white" size={60} />,
                        title: "FULL PROFESSIONAL APP",
                        price: 1800,
                        items: [
                            "Project consultation",
                            "3-20 application pages",
                            "Approved designs",
                            "Multi-device testing",
                            "App store deployment",
                            "Marketing options",
                            "Database & storage",
                            "CMS web portal",
                            "Ecommerce ready"
                        ]
                    },
                ]}
            />
            </div>
            <CasePreview />
        </Page>
    )
}

export default MobileApps