import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import { PackagesItemType } from "./Packages";
import { AiOutlineCheckCircle } from "react-icons/ai"
import { Link, useNavigate } from "react-router-dom";


interface Props {
    item: PackagesItemType
}

const PackagesItem = ({ item }: Props) => {
    const navigate = useNavigate();

    return (
        <Col className="p-4 packagesItem-container">
            <div className="packagesItem">
                <div className="packagesItem-top">
                    {item.icon}
                    <p className="packagesItem-top__title">{item.title}</p>
                    <p className="packagesItem-top__price">£{item.price}*</p>
                </div>
                <div className="packagesItem-bottom">
                    <div className="mb-3">
                    {item.items.map((value) => {
                        return (
                            <div className="packagesItem-bottom-item">
                                <AiOutlineCheckCircle color="#FC9216" size={30} />
                                <p>{value}</p>
                            </div>
                        )
                    })}
                    </div>
                    <Link to={`/contact-us/${item.title.replaceAll(" ", "-").toLocaleLowerCase()}`} className='tdButtonGradient w-100 text-center'>ENQUIRE</Link>

                </div>
            </div>
        </Col>
    )
}

export default PackagesItem