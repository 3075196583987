import NavLinks from "./NavLinks"
import logo from "../Images/logo.png"
interface Props {
    page: string
}

const SmallLanding = ({page}: Props) => {
    return (
        <div className="smallLanding">
            <div className="smallLanding-grey">
            <div className="smallLanding-nav">
                <img src={logo} className="smallLanding-nav__logo" />
                <NavLinks active={page} />
            </div>
            <div className="smallLanding-center">
                <h1>{page.toLocaleUpperCase()}</h1>
            </div>
            </div>
        </div>
    )
}

export default SmallLanding