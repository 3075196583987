import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { FaHandshake, FaDrawPolygon, FaLaptopCode, FaRocket } from "react-icons/fa"

const DevProcess = () => {
    const media = window.matchMedia("(max-width: 950px)").matches

 return (
    <Container>
    <div className="devProcess">
        <div className="devProcess-text">
            <h2>DEVELOPMENT PROCESS</h2>
            <p className="devProcess-text__desc">Our development process is a meticulously structured and collaborative journey, designed to transform ideas into functional and robust digital solutions. We take pride in our transparent and client-centric approach, ensuring that our clients are involved at every step.</p>
        </div>
        <div className="devProcess-cards">
            <Row className={`${media ? "m-0": "mb-5"}`}>
                <Col>
                    <div className="devProcess-cards-item">
                        <div className="devProcess-cards-item-gold">
                            <FaHandshake color="white" size={70} />
                        </div>
                        <div className="devProcess-cards-item-text">
                            <h5 className="devProcess-cards-item-text__title">1. PLANNING</h5>
                            <p>This phase involves defining the goals of the system, identifying the target audience, and establishing the features and functionalities the system will require.</p>
                        </div>
                    </div>
                </Col>
                <Col className="d-flex justify-content-end">
                    <div className="devProcess-cards-item">
                        <div className="devProcess-cards-item-gold">
                            <FaDrawPolygon color="white" size={70} />
                        </div>
                        <div className="devProcess-cards-item-text">
                            <h5 className="devProcess-cards-item-text__title">2. DESIGN</h5>
                            <p>This involves creating a mockup design, choosing a color scheme and typography that reflects the brand identity and ensuring the system's design is user-friendly.</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="devProcess-cards-item">
                        <div className="devProcess-cards-item-gold">
                            <FaLaptopCode color="white" size={70} />
                        </div>
                        <div className="devProcess-cards-item-text">
                            <h5 className="devProcess-cards-item-text__title">3. DEVELOPMENT</h5>
                            <p>The development phase is where the systems functionality is built, and the design is brought to life. The functionality is tested to ensure it works correctly on all devices.</p>
                        </div>
                    </div>
                </Col>
                <Col className="d-flex justify-content-end">
                <div className="devProcess-cards-item">
                        <div className="devProcess-cards-item-gold">
                            <FaRocket color="white" size={70} />
                        </div>
                        <div className="devProcess-cards-item-text">
                            <h5 className="devProcess-cards-item-text__title">4.DEPLOYMENT</h5>
                            <p>During this phase, user behavior is monitored using analytics tools, and feedback is collected and used to make improvements to the digital solution.</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
</Container>
 )
}

export default DevProcess