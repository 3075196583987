import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Websites from './Pages/Websites';
import MobileApps from './Pages/MobileApps';
import CaseStudies from './Pages/CaseStudies';
import ContactUs from './Pages/ContactUs';
import { ToastContainer } from 'react-toastify';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';

function App() {
  return (
    <>
    <ToastContainer />
    <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/websites' element={<Websites />} />
        <Route path='/mobile-apps' element={<MobileApps />} />
        <Route path='/case-studies' element={<CaseStudies />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/contact-us/:source' element={<ContactUs />} />
        <Route path='/terms-and-conditions' element={<Terms />} />
        <Route path='/privacy-policy' element={<Privacy />} />
      </Routes>
      </>
  );
}

export default App;
