import Button from "react-bootstrap/Button"
import d2d from "../Images/work/d2dWebColor.png"
import d2dHome from "../Images/work/d2dWebHome.png"
import thercoSerck from "../Images/work/thercoWeb.png"
import thercoSerckEnq from "../Images/work/thercoEnq.png"
import thercoSerckInv from "../Images/work/thercoIn.png"
import thercoSerckPar from "../Images/work/thercoPar.png"
import vaultStudios from "../Images/work/vaultPreview.png"
import flex from "../Images/work/flexPreview.png"
import flexFull from "../Images/work/Flex.png"
import FsLightbox from "fslightbox-react";
import { useState } from "react"
import vault from "../Images/work/vault.png"
import { Link } from "react-router-dom"

const CasePreview = () => {
    const [open, setOpen] = useState(false);

    const [image, setImage] = useState<string[]>([])
    return (
        <div className="casePreview">
            <FsLightbox
                toggler={open}
                sources={image}
                slide={1}
            />
            <div style={{ background: `url(${d2d})` }} className="casePreview-item">
                <div className="casePreview-item-content">
                    <div className="casePreview-item-content-text">
                        <p>DAY2DAY</p>
                        <Button onClick={() => {
                            setImage([d2d, d2dHome])
                            setOpen(!open)
                        }} className='tdButtonGradient'>VIEW PROJECT</Button>

                    </div>
                </div>
            </div>
            <div style={{ background: `url(${thercoSerck})` }} className="casePreview-item">
                <div className="casePreview-item-content">
                    <div className="casePreview-item-content-text">
                        <p>Therco-Serck</p>
                        <Button onClick={() => {
                            setImage([thercoSerck, thercoSerckEnq, thercoSerckInv, thercoSerckPar])
                            setOpen(!open)
                        }} className='tdButtonGradient'>VIEW PROJECT</Button>
                    </div>
                </div>
            </div>
            <div style={{ background: `url(${vaultStudios})` }} className="casePreview-item">
                <div className="casePreview-item-content">
                    <div className="casePreview-item-content-text">
                        <p>Vault Studios</p>
                        <Button onClick={() => {
                            setImage([vault])
                            setOpen(!open)
                        }} className='tdButtonGradient'>VIEW PROJECT</Button>
                    </div>
                </div>
            </div>
            <div style={{ background: `url(${flex})` }} className="casePreview-item">
                <div className="casePreview-item-content">
                    <div className="casePreview-item-content-text">
                        <p>Flex Sportswear</p>
                        <Button onClick={() => {
                            setImage([flexFull])
                            setOpen(!open)
                        }} className='tdButtonGradient'>VIEW PROJECT</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CasePreview