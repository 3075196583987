import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import webdev from "../Images/webDev.png"
import web from "../Images/work/smartmockups_lkcak7ok.jpg"
import app from "../Images/work/d2dApp.jpg"
import dm from "../Images/work/smartmockups_lkcabp53.jpg"
import { Link, useNavigate } from "react-router-dom";

const HomeSlide = () => {
    const navigate = useNavigate()

    return (
        <Carousel interval={10000}>
            <Carousel.Item>
                <div className='HomeSlide-item' style={{background: `url(${web})`}}>
                    <div className='HomeSlide-item-info'>
                        <h2 className='HomeSlide-item-info__title'>WEBSITE DEVELOPMENT</h2>
                        <p className='mb-4'>Our website design and development service is dedicated to providing top-quality solutions that help businesses establish a strong online presence and achieve their business goals.</p>
                        <Link to={"/contact-us/website"} className='tdButtonGradient'>RECEIVE QUOTE</Link>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
            <div className='HomeSlide-item' style={{background: `url(${app})`}}>
                    <div className='HomeSlide-item-info'>
                        <h2 className='HomeSlide-item-info__title'>APP DEVELOPMENT</h2>
                        <p className='mb-4'>Our app design and development service specializes in creating innovative and user-friendly mobile applications that help businesses reach their target audience.</p>
                        <Link to={"/contact-us/mobile-application"} className='tdButtonGradient'>RECEIVE QUOTE</Link>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
            <div className='HomeSlide-item' style={{background: `url(${dm})`}}>
                    <div className='HomeSlide-item-info'>
                        <h2 className='HomeSlide-item-info__title'>DIGITAL MARKETING</h2>
                        <p className='mb-4'>Our digital marketing service boosts your online presence and visibilty to all or a selected target audience. We do this by managing your SEO and branding across all your digital services.</p>
                        <Link to={"/contact-us/digital-marketing"} className='tdButtonGradient'>RECEIVE QUOTE</Link>
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
    )
}

export default HomeSlide