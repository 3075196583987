import { Button } from "react-bootstrap"
import NavLinks from "../Components/NavLinks"
import logo from "../Images/icon.png"
import {FaFacebookSquare, FaInstagramSquare} from "react-icons/fa"
import {BsChevronCompactDown} from "react-icons/bs"
import { Link, useNavigate } from "react-router-dom";
const LargeLanding = () => {
    const media = window.matchMedia("(max-width: 1550px)").matches

    const navigate = useNavigate()
    return (
        <div className="home">
                    <div className="home-landing">
                        <div className="home-landing-nav">
                            <img src={logo} className="home-landing-nav__logo" />
                            <NavLinks active="Home" />
                        </div>
                        <div className="home-landing-center">
                            <h1>TOSELAND</h1>
                            <h1 className="home-landing-center__digital">DIGITAL</h1>
                            <div className="home-landing-center-icons">
                                <a className="icon-link" href="https://www.facebook.com/profile.php?id=100094673271483" target="_"><FaFacebookSquare className="home-landing-center-icons__item" color="white" size={35}/></a>
                                <a className="icon-link" href="https://www.instagram.com/toseland_digital/" target="_"><FaInstagramSquare className="home-landing-center-icons__item" color="white" size={35}/></a>
                            </div>
                            <Link to="/contact-us" className="tdButton">RECEIVE A QUOTE</Link>
                        </div>
                        <div className="home-landing-footer">
                            <BsChevronCompactDown color="white"
                            size={50}/>
                        </div>
                    </div>
                </div>
    )
}

export default LargeLanding