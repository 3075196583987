import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore, getDocs, collection, setDoc, doc, addDoc, getDoc } from "firebase/firestore";

export interface Email {
  to: string[]
  message: {
    subject: string
    text?: string
    html?: string
  }
}

const firebaseConfig = {
    apiKey: "AIzaSyDBJR5mEJFAUkKc8LqnKXSRjXQjBJmz_hw",
    authDomain: "toselanddigital.firebaseapp.com",
    projectId: "toselanddigital",
    storageBucket: "toselanddigital.appspot.com",
    messagingSenderId: "1050844797453",
    appId: "1:1050844797453:web:69d2b1d79b39dca3899344",
    measurementId: "G-Z4GZLHHBMY"
  };

  const app = initializeApp(firebaseConfig);

  const analytics = getAnalytics(app);

  const db = getFirestore(app);

export const sendContactForm = async (type: string, name: string, email: string, mobile: string, message: string) => {
    const temp: Email = {
        to: ["enquiries@toselanddigital.com"],
        message: {
            subject: `Sales lead: An online contact form was filled in`,
            html: `
        <p>A new online contact form was submitted</p>
        <p>Type: ${type}</p>
        <p>Name: ${name}</p>
        <p>Email: ${email}</p>
        <p>Mobile: ${mobile}</p>
        <p>Message: ${message}</p>
        `
        }
    }
    await addDoc(collection(db, "mail"), temp)
    logEvent(analytics, "Contact form completed")
}