import CasePreview from "../Components/CasePreview"
import Page from "../Components/Page"
import { Row, Col, Container, Button, Form } from "react-bootstrap"
import { useState, useEffect } from "react"
import { ToastContainer, toast } from 'react-toastify';
import {BsCheckCircle, BsCheckCircleFill} from "react-icons/bs"
import { sendContactForm } from "../Utilities/helperAPI";
import {useParams} from "react-router-dom"
import Websites from "./Websites";

const ContactUs = () => {
    const [name, setName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [mobile, setMobile] = useState<string>("")
    const [message, setMessage] = useState<string>("")
    const [type, setType] = useState<string>("")
    const [agree, setAgree] = useState<boolean>(false)
    const [submit, setSubmit] = useState<boolean>(false)

    const media = window.matchMedia("(max-width: 950px)").matches
    let { source } = useParams()
    
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        if(source === "starter-website") {
            setType("New website")
            setMessage("I am interested in the starter website package. Please contact me to discuss further.")
        }
        if(source === "professional-website") {
            setType("New website")
            setMessage("I am interested in the professional website package. Please contact me to discuss further.")
        }
        if(source === "ecommerce-website") {
            setType("New website")
            setMessage("I am interested in the ecommerce website package. Please contact me to discuss further.")
        }
        if(source === "starter-application") {
            setType("New mobile app")
            setMessage("I am interested in the starter mobile application package. Please contact me to discuss further.")
        }
        if(source === "user-based-application") {
            setType("New mobile app")
            setMessage("I am interested in the user based mobile application package. Please contact me to discuss further.")
        }
        if(source === "full-professional-app") {
            setType("New mobile app")
            setMessage("I am interested in the full professional mobile application package. Please contact me to discuss further.")
        }
        if(source === "website") {
            setType("New website")
        }
        if(source === "mobile-application") {
            setType("New mobile app")
        }
        if(source === "digital-marketing") {
            setType("Digital marketing")
        }
    }, [])
    
    const validate = () => {
        if (name === "") {
            toast.error('Name field is required', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
        if (email === "") {
            toast.error('Email field is required', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
        if (message === "") {
            toast.error('Message field is required', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
        if (type === "") {
            toast.error('Service field is required', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
        if (agree === false) {
            toast.error('Agreement statement required', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
        if (name !== "" && email !== "" && type !== "" && message !== "" && agree !== false) {
            setSubmit(true)
            sendContactForm(type, name, email, mobile, message)
        }
    }

    return (
        <Page page="Contact Us">
            <div className="packages-bg">
            <Container className={`${media ? "pt-4 pb-4": "pb-5 pt-5"}`}>
                <Row className="contact-row">
                    <Col className={`${media ? "p-2 pb-4": "pr"}`}>
                        <h2>GET IN TOUCH WITH US</h2>
                        <p className="mb-2">Thank you for considering Toseland Digital for your business needs. We are committed to providing top-quality digital solutions that help businesses succeed in today's digital world.</p>
                        <p className="mb-4">If you have any questions about our services, pricing, or would like to discuss a potential project, please don't hesitate to contact us. You can reach us by filling in the contact form or by the contact methods below. Our team will respond to your enquiry as soon as possible.</p>
                        <div className="d-flex flex-column">
                            <a className="fit-content mb-3" href="mailto:Enquiries@ToselandDigital.com">Enquiries@ToselandDigital.com</a>
                            <a className="fit-content" href="tel:07719450486">07719450486</a>
                        </div>
                    </Col>
                    <Col className={`${media ? "p-0 pb-1": "pl"}`}>
                    <div className="p-5 form-bg">
                        {submit ?
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <BsCheckCircle className="mb-3 icon-gradient" color="white" size={90}/>
                        <h3 className="font-bold mb-2 text-center">YOUR MESSAGE HAS BEEN SENT</h3>
                        <p className="text-center">Thank you for taking an intrest in our services. Our team will be in touch shortly via email or phone regarding your enquiry.</p>
                    </div>
                    :
                    <Form>
                            <Form.Group className="mb-1">
                                <Form.Label>What do you require?:*</Form.Label>
                                <Form.Select value={type} onChange={(e) => setType(e.currentTarget.value)}>
                                    <option value="">Select an option...</option>
                                    <option value="New website">New website</option>
                                    <option value="New mobile app">New mobile app</option>
                                    <option value="Digital marketing">Digital marketing</option>
                                    <option value="Other">Other enquiry</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-1">
                                <Form.Label>Full name:*</Form.Label>
                                <Form.Control value={name} onChange={(e) => setName(e.currentTarget.value)} placeholder="Enter full name..." />
                            </Form.Group>
                            <Form.Group className="mb-1">
                                <Form.Label>Email address:*</Form.Label>
                                <Form.Control value={email} onChange={(e) => setEmail(e.currentTarget.value)} type="email" placeholder="Enter email address..." />
                            </Form.Group>
                            <Form.Group className="mb-1">
                                <Form.Label>Phone number:</Form.Label>
                                <Form.Control value={mobile} onChange={(e) => setMobile(e.currentTarget.value)} placeholder="Enter phone number..." />
                            </Form.Group>
                            <Form.Group className="mb-1">
                                <Form.Label>Message:*</Form.Label>
                                <Form.Control value={message} onChange={(e) => setMessage(e.currentTarget.value)} as="textarea" rows={3} placeholder="Enter message..." />
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Check
                                    onChange={(e) => setAgree(e.currentTarget.checked)}
                                    type={"checkbox"}
                                    label={"You agree that Toseland Digital may store this data for contacting you regarding the enquiry.*"}
                                />
                            </Form.Group>
                            <div className="w-100 d-flex justify-content-center">
                                <Button onClick={() => {
                                    validate()
                                }} className="tdButtonGradient" variant="primary">
                                    SEND MESSAGE
                                </Button>
                            </div>
                        </Form>
                    }
                    </div>
                    </Col>
                </Row>
            </Container>
            </div>
            <CasePreview />
        </Page>
    )
}

export default ContactUs