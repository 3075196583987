import { Link } from "react-router-dom"
import {BiMenuAltRight} from "react-icons/bi"
import {AiOutlineClose} from "react-icons/ai"
import {useState, useEffect} from "react"
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from "../Images/logo.png"

interface Props {
    active: string;
}

const NavLinks = ({ active }: Props) => {
    const media = window.matchMedia("(max-width: 950px)").matches
    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const activeClass = "nav-links__item--active"
    
    return (
        <>
            {media ?
                <BiMenuAltRight onClick={() => setShow(!show)} color="white" size={60}/>
                :
                <ul className="nav-links">
                    <Link to="/" className={`nav-links__item ${active === "Home" && activeClass}`}>Home</Link>
                    <Link to="/about-us" className={`nav-links__item ${active === "About Us" && activeClass}`}>About Us</Link>
                    <Link to="/websites" className={`nav-links__item ${active === "Websites" && activeClass}`}>Websites</Link>
                    <Link to="/mobile-apps" className={`nav-links__item ${active === "Mobile Apps" && activeClass}`}>Mobile Apps</Link>
                    <Link to="/case-studies" className={`nav-links__item ${active === "Recent Case Studies" && activeClass}`}>Case Studies</Link>
                    <Link to="/contact-us" className={`nav-links__item ${active === "Contact Us" && activeClass}`}>Contact Us</Link>
                </ul>
            }
            {media &&
            <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Body>
              <div className="d-flex flex-row justify-content-between align-items-center p-3">
              <img src={logo} className="home-landing-nav__logo w-75" />
              <AiOutlineClose onClick={() => setShow(!show)} color="white" size={40}/>
              </div>
              <ul className="nav-links">
                    <Link to="/" className={`nav-links__item ${active === "Home" && activeClass}`}>Home</Link>
                    <Link to="/about-us" className={`nav-links__item ${active === "About Us" && activeClass}`}>About Us</Link>
                    <Link to="/websites" className={`nav-links__item ${active === "Websites" && activeClass}`}>Websites</Link>
                    <Link to="/mobile-apps" className={`nav-links__item ${active === "Mobile Apps" && activeClass}`}>Mobile Apps</Link>
                    <Link to="/case-studies" className={`nav-links__item ${active === "Recent Case Studies" && activeClass}`}>Case Studies</Link>
                    <Link to="/contact-us" className={`nav-links__item ${active === "Contact Us" && activeClass}`}>Contact Us</Link>
                </ul>
            </Offcanvas.Body>
          </Offcanvas>
            }
        </>

    )
}

export default NavLinks