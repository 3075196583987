import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import logo from "../Images/logo.png"
import { Link } from "react-router-dom"
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa"
const Footer = () => {
    return (
        <Row className="footer">
                <Col className="d-flex align-items-center">
                    <img src={logo} className="footer__logo" />
                </Col>
                <Col>
                    <h5 className="footer__title">NAVIGATION</h5>
                    <Row>
                        <Col className="d-flex flex-column">
                            <Link className="footer__link" to="/">Home</Link>
                            <Link className="footer__link" to="/about-us">About Us</Link>
                            <Link className="footer__link" to="/websites">Websites</Link>
                        </Col>
                        <Col className="d-flex flex-column">
                            <Link className="footer__link" to="/mobile-apps">Mobile Apps</Link>
                            <Link className="footer__link" to="/case-studies">Case Studies</Link>
                            <Link className="footer__link" to="/contact-us">Contact Us</Link>
                        </Col>
                    </Row>
                </Col>
                <Col className="d-flex flex-column">
                    <h5 className="footer__title">CONTACT US</h5>
                    <a className="footer__link" href="mailto:Enquiries@ToselandDigital.com">Enquiries@ToselandDigital.com</a>
                    <a className="footer__link" href="tel:07719450486">07719450486</a>
                    <div className="d-flex flex-row">
                    <a className="icon-link" href="https://www.facebook.com/profile.php?id=100094673271483" target="_"><FaFacebookSquare className="footer__icon" color="white" size={30} /></a>
                        <a className="icon-link" href="https://www.instagram.com/toseland_digital/" target="_"><FaInstagramSquare color="white" size={30} /></a>
                    </div>
                </Col>
                <Col className="d-flex flex-column">
                    <h5 className="footer__title">INFORMATION</h5>
                    <Link className="footer__link" to="/terms-and-conditions">Terms & Conditions</Link>
                    <Link className="footer__link" to="/privacy-policy">Privacy Policy</Link>
                </Col>
        </Row>
    )
}

export default Footer