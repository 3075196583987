import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
interface Props {
    title: string;
    children: React.ReactNode;
    image: string;
    reverse?: boolean;
    black?:boolean;
}

const TextImage = ({children, image, title, reverse = false, black}: Props) => {
    return (
        <div className={`${black && "textImageReverse-color"} ${reverse && "textImageReverse-color"}`}>
        <Container>
        <Row className={`${reverse ? "textImageReverse" : "textImage"}`}>
            <Col className={`${reverse ? "textImageReverse-text" : "textImage-text"}`} sm={8}>
                <h2>{title}</h2>
                <div className="w-90">
                {children}

                </div>
            </Col>
            <Col className={`${reverse ? "textImageReverse-image" : "textImage-image"}`} sm={4}>
                <div className={`${reverse ? "textImageReverse-image__item" : "textImage-image__item"}`} style={{background: `url(${image})`}}>
                </div>
            </Col>
        </Row>
        </Container>
        </div>
    )
}

export default TextImage