import { Container, Row } from "react-bootstrap"
import Page from "../Components/Page"

const Privacy = () => {
    return (
        <Page page="Privacy Policy">
            <Container className="pt-4 pb-4">
            <Row className="mb-3">
                <h3>1. Information Collection</h3>
                <p>We may collect personal information from you when you fill out forms on our website, subscribe to our newsletter, or interact with us through other communication channels. The information we may collect includes but is not limited to:</p>
                <ul className="mt-2">
                    <li className="mb-1">Name</li>
                    <li className="mb-1">Email address</li>
                    <li className="mb-1">Phone number</li>
                    <li className="mb-1">Company name</li>
                    <li className="mb-1">Website URL</li>
                </ul>
                </Row>
                <Row className="mb-3">
                <h3>2. Use of Information</h3>
                <p>We may use the collected information for the following purposes:</p>
                <ul className="mt-2">
                    <li className="mb-1">Respond to your inquiries and provide customer support</li>
                    <li className="mb-1">Send you relevant information about our services</li>
                    <li className="mb-1">Process transactions for our services</li>
                    <li className="mb-1">Improve our website and services</li>
                    <li className="mb-1">Send periodic newsletters or marketing materials (only if you have opted-in)</li>
                </ul>
                </Row>
                <Row className="mb-3">
                <h3>3. Information Sharing</h3>
                <p>We respect your privacy and do not sell, trade, or transfer your personal information to third parties without your explicit consent. However, we may share your information in the following cases:</p>
                <ul className="mt-2">
                    <li className="mb-1">With our trusted service providers who assist us in operating our website and providing services to you</li>
                    <li className="mb-1">When required by law or to protect our rights, safety, or property</li>
                </ul>
                </Row>
                <Row className="mb-3">
                <h3>4. Cookies and Similar Technologies</h3>
                <p>We may use cookies and similar technologies to enhance your experience on our website. Cookies are small files that your browser stores on your device, which helps us remember certain information about your visit and preferences. You can adjust your browser settings to refuse cookies or indicate when a cookie is being sent.</p>
                </Row>
                <Row className="mb-3">
                <h3>5. Third-Party Links</h3>
                <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of such websites. We encourage you to review the privacy policies of these third-party sites before providing any personal information to them.</p>
                </Row>
                <Row className="mb-3">
                <h3>6. Data Security</h3>
                <p>We take reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please understand that no data transmission over the internet or electronic storage method is 100% secure, and we cannot guarantee absolute security.</p>
                </Row>
                <Row className="mb-3">
                <h3>7. Changes to the Privacy Policy</h3>
                <p>We reserve the right to update or modify this Privacy Policy at any time without prior notice. Any changes will be effective immediately upon posting the updated policy on our website.</p>
                </Row>
                <Row className="mb-3">
                <h3>8. Contact Us</h3>
                <p>If you have any questions or concerns regarding this Privacy Policy or how we handle your personal information, please contact us at:</p>
                <p><a href="mailto:Enquiries@ToselandDigital.com">Enquiries@ToselandDigital.com</a></p>
                </Row>
            </Container>
        </Page>
    )
}

export default Privacy