import CasePreview from "../Components/CasePreview"
import Page from "../Components/Page"
import TextImage from "../Components/TextImage"
import image from "../Images/work/smartmockups_lkcaskdc.jpg"

import lt from "../Images/lt.jpg"
import webDev from "../Images/work/smartmockups_lkcavpr9.jpg"
import Quote from "../Components/Quote"

const AboutUs = () => {
    return (
        <Page page="About Us">
                <TextImage black image={image} title="WHO WE ARE">
                    <div>
                        <p className="mb-2">Welcome to Toseland Digital, where we specialize in building stunning and functional websites, apps and software
                                    for businesses of all sizes. Our company is committed to providing top-notch digital design and development
                                    services that help our clients establish a strong online presence and grow their business. </p>
                        <p className="mb-4">Our digital services are built with the latest technology and best practices, ensuring that they are fast, responsive,
                                    and secure. We also provide ongoing support and maintenance services to ensure that your digital service remains up-to-date
                                    and performs at its best.</p>
                        <div className="d-flex flex-column tab-center">
                            <a className="fit-content mb-3" href="mailto:Enquiries@ToselandDigital.com">Enquiries@ToselandDigital.com</a>
                            <a className="fit-content" href="tel:07719450486">07719450486</a>
                        </div>                    </div>
                </TextImage>
                <Quote background={webDev} image={lt} name="Lewis Toseland" quote="I formed Toseland Digital to provide bespoke, affordable websites to starting businesses. In todays world a website is a necessity for a company and defines how we present ourselves to a customer."/>
                <CasePreview />
        </Page>
    )
}

export default AboutUs