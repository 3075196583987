import CasePreview from "../Components/CasePreview"
import HomeSlide from "../Components/HomeSlide"
import Packages from "../Components/Packages"
import Page from "../Components/Page"
import { BsShopWindow, BsPhone, BsWindowFullscreen } from "react-icons/bs"
import Container from "react-bootstrap/Container"

const Home = () => {
    const media = window.matchMedia("(max-width: 1550px)").matches
    return (
        <Page page="Home">
            <HomeSlide />
            <div className="packages-bg">
            <Packages
                title="POPULAR PACKAGES"
                description="Below are some of our most popular packages among clients. With a focus on innovation, creativity, and exceptional user experiences, our design packages consistently receive glowing feedback from our satisfied clients."
                items={[
                    {
                        icon: <BsWindowFullscreen color="white" size={60} />,
                        title: "STARTER WEBSITE",
                        price: 500,
                        items: [
                            "Project consultation",
                            "3-5 Website pages",
                            "Approved designs",
                            "Hosting & security",
                            "SEO optimization"
                        ]
                    },
                    {
                        icon: <BsShopWindow color="white" size={60} />,
                        title: "ECOMMERCE WEBSITE",
                        price: 1300,
                        items: [
                            "Project consultation",
                            "3-20 Website pages",
                            "Approved designs",
                            "Hosting & security",
                            "SEO optimization",
                            "Marketing options",
                            "Database & storage",
                            "CMS user control",
                            "Ecommerce ready"
                        ]
                    },
                    {
                        icon: <BsPhone color="white" size={60} />,
                        title: "FULL PROFESSIONAL APP",
                        price: 1800,
                        items: [
                            "Project consultation",
                            "3-20 Application pages",
                            "Approved designs",
                            "Multi-device testing",
                            "App store deployment",
                            "Marketing options",
                            "Database & storage",
                            "CMS user control",
                            "Ecommerce ready"
                        ]
                    },
                ]}
            />
            </div>
            <CasePreview />
        </Page>
    )
}

export default Home