import React from "react"
import LargeLanding from "./LargeLanding"
import Footer from "./Footer"
import SmallLanding from "./SmallLanding"
import { BsChevronUp } from "react-icons/bs"
import NavLinks from "./NavLinks"
import logo from "../Images/logo.png"
import {useEffect, useState} from "react"
import {BiMenuAltRight} from "react-icons/bi"

interface Props {
    children: React.ReactNode
    page: string
}

const Page = ({ children, page }: Props) => {
    const [show, setShow] = useState<boolean>(false)
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
    },[])
    return (
        <div className="page">
            <div className="fixedNav">
                <img src={logo} className="home-landing-nav__logo fixedNav__logo" />
                <NavLinks active={page} />
            </div>
            {page === "Home" ?
                <LargeLanding />
                :
                <SmallLanding page={page} />
            }
            {children}
            <Footer />
            <div className="page-btt" onClick={() => {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            }}>
                <BsChevronUp color="white" size={30} />
            </div>
        </div>
    )
}

export default Page